import gql from "graphql-tag";

export const regionList = gql`
  query regions {
    regions {
      id
      name
      country {
        id
        name
      }
    }
  }
`;
