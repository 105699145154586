<template>
  <KTCard data-cy="reset-password-page" :title="$t('page.resetPassword.recoverPassword')">
    <b-form novalidate>
      <InputElement
        v-model="userEmail"
        data-cy="email-input"
        input-type="email"
        :label="$t('label.email')"
        disabled
      />
    </b-form>

    <template v-slot:footer>
      <div class="mr-auto">
        <ButtonElement
          data-cy="reset-btn"
          variant="primary"
          text="resetPassword"
          class="mr-3"
          @click="onClickSubmit"
        />

        <ButtonElement data-cy="back-btn" variant="text-primary" text="back" @click="onClickBack" />
      </div>
    </template>
  </KTCard>
</template>

<script>
import KTCard from "@/components/card/KTCard";
import ButtonElement from "@/components/ButtonElement";
import InputElement from "@/components/form/InputElement";
import notify from "@/services/notify.service";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ResetPassword",
  components: {
    KTCard,
    ButtonElement,
    InputElement,
  },

  computed: {
    ...mapGetters(["userEmail"]),
  },

  mounted() {
    this.$setBreadcrumbs([], true);
  },

  methods: {
    ...mapActions(["resetPassword"]),

    async onClickSubmit() {
      if (!this.userEmail) return;

      const response = await this.resetPassword({ email: this.userEmail });

      notify.success(response.data.message);
    },

    onClickBack() {
      this.$router.push({ name: "ChangePassword" });
    },
  },
};
</script>
