import gql from "graphql-tag";

export const updateProfile = gql`
  mutation updateProfile(
    $firstName: String
    $lastName: String
    $phone: String
    $regionId: ID
    $language: String
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      regionId: $regionId
      language: $language
    ) {
      id
      firstName
      lastName
      email
      type
      language
      roles {
        id
        name
      }
      detail {
        ... on Agent {
          id
          status
          phone
          region {
            id
            name
          }
          comment
        }
        ... on Employee {
          id
        }
      }
    }
  }
`;
