import gql from "graphql-tag";

export const updateProfile = gql`
  mutation updateProfile(
    $firstName: String
    $lastName: String
    $middleName: String
    $taxNumber: String
    $iban: String
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      taxNumber: $taxNumber
      iban: $iban
    ) {
      firstName
      lastName
      detail {
        __typename
        ... on Agent {
          middleName
          taxNumber
          iban
        }
      }
    }
  }
`;
