<template>
  <KTCard data-cy="requisites-page" :title="$t('page.requisites.title')">
    <RequisitesForm ref="requisitesForm" v-model="form" />

    <template v-slot:footer>
      <div class="mr-auto">
        <ButtonElement
          data-cy="submit-btn"
          variant="primary"
          text="save"
          class="mr-3"
          @click="onClickSubmit"
        />

        <ButtonElement variant="text-primary" text="back" @click="onClickBack" />
      </div>
    </template>
  </KTCard>
</template>

<script>
import { mapGetters } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { updateProfile } from "./gql/mutations";

import KTCard from "@/components/card/KTCard";
import RequisitesForm from "@/components/RequisitesForm.vue";
import ButtonElement from "@/components/ButtonElement";

export default {
  name: "Requisites",

  components: {
    KTCard,
    RequisitesForm,
    ButtonElement,
  },

  mixins: [apolloMixin],

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        middleName: "",
        taxNumber: "",
        iban: "",
      },
    };
  },

  computed: {
    ...mapGetters(["userRequisites"]),
  },

  mounted() {
    this.$setBreadcrumbs([], true);
  },

  created() {
    this.getRequisite();
  },

  methods: {
    async getRequisite() {
      this.form = { ...this.userRequisites };
    },

    async onClickSubmit() {
      this.$refs.requisitesForm.emitForm();

      if (this.form.isValid) {
        await this.$post(updateProfile, this.form);
        this.form.isValid = false;
      }
    },

    onClickBack() {
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>
