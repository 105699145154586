<template>
  <KTCard data-cy="profile-edit-page" :title="$t('page.profile.editProfile')">
    <b-form novalidate>
      <InputElement
        v-model="form.lastName"
        data-cy="last-name-input"
        class="mb-10"
        required
        :with-error="errors.lastName"
        :error-message="$t('validation.required')"
        :label="$t('label.lastName')"
        @blur="removeErrorClass('lastName')"
        @input="removeErrorClass('lastName')"
      />

      <InputElement
        v-model="form.firstName"
        data-cy="first-name-input"
        class="mb-10"
        required
        :with-error="errors.firstName"
        :error-message="$t('validation.required')"
        :label="$t('label.firstName')"
        @blur="removeErrorClass('firstName')"
        @input="removeErrorClass('firstName')"
      />

      <CleaveElement
        :value="phoneWithPlus"
        :options="cleaveOptions"
        data-cy="phone-input"
        class="mb-10"
        required
        :label="$t('label.phone')"
        :with-error="errors.phone"
        :error-message="phoneErrorMsg"
        @input="onChangePhone"
        @blur="removeErrorClass('phone')"
      />

      <InputElement
        v-model="form.email"
        data-cy="email-input"
        class="mb-10"
        disabled
        input-type="email"
        :label="$t('label.email')"
      />

      <MultiselectElement
        :value="checkValueExists(form.region)"
        data-cy="region-select"
        :options="regions"
        item-label="name"
        :label="$t('label.region')"
        @select="onSelectRegion"
        @open="removeErrorClass('region')"
      />
    </b-form>

    <template v-slot:footer>
      <div class="mr-auto">
        <ButtonElement
          data-cy="submit-btn"
          variant="primary"
          text="save"
          class="mr-3"
          @click="onClickSubmit"
        />

        <ButtonElement variant="text-primary" text="back" @click="onClickBack" />
      </div>
    </template>
  </KTCard>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { regionList } from "./gql/queries";
import { updateProfile } from "./gql/mutations";
import KTCard from "@/components/card/KTCard";
import ButtonElement from "@/components/ButtonElement";
import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";
import CleaveElement from "@/components/form/CleaveElement";

export default {
  name: "ProfileEdit",
  components: {
    KTCard,
    ButtonElement,
    InputElement,
    MultiselectElement,
    CleaveElement,
  },

  mixins: [apolloMixin],

  data: () => ({
    regions: [],
    form: {
      firstName: "",
      lastName: "",
      phone: "38",
      email: "",
      region: null,
    },
    errors: {
      firstName: false,
      lastName: false,
      phone: false,
    },
    cleaveOptions: {
      phone: true,
      phoneRegionCode: "UA",
      delimiter: "-",
    },
    phoneMinLength: 11,
    phoneMaxLength: 13,
  }),

  computed: {
    ...mapGetters(["userProfile"]),

    phoneWithPlus() {
      return `+${this.form.phone}`;
    },

    phoneNumberLength() {
      const phoneRegExp = /\D/g;

      return this.form.phone.replace(phoneRegExp, "").length;
    },

    phoneErrorMsg() {
      return !this.phoneNumberLength
        ? this.$t("validation.required")
        : this.$t("validation.phoneLength");
    },

    locale() {
      return this.$i18n.locale;
    },
  },

  watch: {
    userProfile: {
      handler: "onChangeProfile",
      immediate: true,
    },
  },

  async created() {
    if (!this.regions.length) await this.getRegions();
  },

  mounted() {
    this.$setBreadcrumbs([], true);
  },

  methods: {
    ...mapMutations(["SET_PROFILE"]),

    async getRegions() {
      const { regions } = await this.$get(regionList);

      // TODO: fix when will be country switch
      // this.regions = regions.map((region) => ({ ...region, name: region.name[this.locale] }));
      this.regions = regions.map((region) => ({ ...region, name: region.name["ua"] }));
    },

    onChangeProfile(profile) {
      const isExistProfile = profile && Object.keys(profile).length;
      const isRegion = !!profile.detail?.region;

      if (isExistProfile) {
        let region = "";

        if (isRegion) {
          region = {
            id: profile.detail.region.id,
            // TODO: fix when will be country switch
            // name: profile.detail.region.name[this.locale],
            name: profile.detail.region.name["ua"],
          };
        }

        this.form.firstName = profile.firstName;
        this.form.lastName = profile.lastName;
        this.form.phone = profile.detail.phone;
        this.form.email = profile.email;
        this.form.region = region;
      }
    },

    onChangePhone(phone) {
      this.form.phone = phone;
      this.removeErrorClass("phone");
    },

    onSelectRegion(region) {
      this.form.region = region;
    },

    checkValueExists(value) {
      return value ? [value] : [];
    },

    removeErrorClass(key) {
      this.errors[key] = false;
    },

    isValidForm() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.firstName = !this.form.firstName;
      this.errors.lastName = !this.form.lastName;
      this.errors.phone = !this.phoneNumberLength;
      this.errors.phone =
        this.phoneNumberLength < this.phoneMinLength ||
        this.phoneNumberLength > this.phoneMaxLength;

      return !Object.values(this.errors).some((error) => error);
    },

    async onClickSubmit() {
      if (!this.isValidForm()) return;

      const formData = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        phone: this.form.phone,
        regionId: this.form.region?.id || null,
      };

      const response = await this.$post(updateProfile, formData);

      this.SET_PROFILE(response.updateProfile);
    },

    onClickBack() {
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>
