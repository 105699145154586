<template>
  <KTCard data-cy="notifications-page" :title="$t('page.notification.title')">
    <h2 style="height: 300px">Notifications in progress ...</h2>
  </KTCard>
</template>

<script>
import KTCard from "@/components/card/KTCard";

export default {
  name: "Notifications",
  components: {
    KTCard,
  },

  mounted() {
    this.$setBreadcrumbs([], true);
  },
};
</script>
